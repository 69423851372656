import { useQueryClient } from "@tanstack/react-query"
import { Account } from "~/types"

type UseAccountData = {
  account: Account | undefined
  setAccount: (account: Account | null) => void
}

export default function useAccount(): UseAccountData {
  const queryClient = useQueryClient()

  const account = queryClient.getQueryData<Account>(["account"])

  const setAccount = (account: Account | null) => {
    queryClient.setQueryData(["account"], () => account)
  }

  if (account) {
    account.isPremium = new Date(account?.premium_ends_at || 0) > new Date()
  }

  return {
    setAccount,
    account,
  }
}
